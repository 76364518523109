import React from "react";
import { Container, Grid, Typography } from "@mui/material";
import { graphql, useStaticQuery } from "gatsby";
import { ServiceCard } from "content/cards/service-card";
import { Service } from "models/schemas/service";
import { GraphQlArray } from "models/graphql/graphql-array";

export type ServicesBannerProps = {
    services: GraphQlArray<Service>
}

const ServicesBanner: React.FC = () => {

    const { services }: ServicesBannerProps = useStaticQuery(query);

    return (
        <Container maxWidth={"lg"}>
            <Grid container>
                <Grid container item spacing={4}>
                    <Grid item xs={12}>
                        <Typography variant={"h6"} textAlign={"center"} color={"textSecondary"}>
                            We offer a wide variety of services for companies of any size
                        </Typography>
                        <Typography variant={"h6"} textAlign={"center"} fontWeight={"bold"} color={"textPrimary"}>from
                            startups to
                            enterprise-level</Typography>
                    </Grid>
                    <Grid container item justifyContent={"center"} spacing={4}>
                        { services.nodes.map(service => (
                            <Grid key={service.page.link.label} item xs={12} sm={6} md={4} lg={3}>
                                <ServiceCard
                                    title={service.page.link.label}
                                    description={service.summary}
                                    link={service.page.link}
                                />
                            </Grid>
                        ))}
                    </Grid>
                </Grid>
            </Grid>
        </Container>
    );
}
export { ServicesBanner }

const query = graphql`
{
    services: allGraphCmsService {
        nodes {
            page {
                link {
                    label
                    url
                }
                metadata{
                    title
                }
            }
            summary
        }
    }
}
`;