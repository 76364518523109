import React from "react";

import { Box, Button, Grid, Typography } from "@mui/material";
import { Layout } from "layouts/layout";
import { LandingBanner } from "content/banners/landing-banner";
import { graphql, HeadFC, HeadProps, Link, PageProps } from "gatsby";
import { CustomersBanner } from "content/banners/customers-banner";
import { ServicesBanner } from "content/banners/services-banner";
import { ProductsBanner } from "content/banners/products-banner";

import "themes/core.scss"
import { PageMetadata } from "components/layout/page-metadata";
import { AssetBanner } from "content/banners/asset-banner";
import { Page } from "models/schemas/page";

export type IndexProps = {
    page: Page
}

const Index: React.FC<PageProps<IndexProps>> = ({ data }) => {

    const { page } = data;

    return (
        <Layout>
            <Grid container>
                {page.banner &&
                    <AssetBanner asset={page.banner}/>
                }
                <Grid container item spacing={20}>
                    <Grid item container>
                        <Grid item xs={12}>
                            <LandingBanner/>
                        </Grid>
                    </Grid>

                    <Grid item container>
                        <Grid item xs={12} position={"relative"}>
                            <Box className={"radial-gradient"} height={"250%"}/>
                            <ServicesBanner/>
                        </Grid>
                    </Grid>

                    <Grid item xs={12}>
                        <CustomersBanner/>
                    </Grid>

                    <Grid item container>
                        <Grid item xs={12} position={"relative"}>
                            <Box className={"radial-gradient"}/>
                            <ProductsBanner/>
                        </Grid>
                    </Grid>

                    <Grid item container spacing={4} minHeight={250} alignContent={"center"}>
                        <Grid container item justifyContent={"center"} spacing={1}>
                            <Grid item xs={12}>
                                <Typography variant={"h5"} align={"center"} color={"textSecondary"}>Ready to get
                                    started?</Typography>
                            </Grid>
                            <Grid item lg={4}>
                                <Typography variant={"h5"} fontWeight={"bold"} align={"center"} color={"textPrimary"}>We're
                                    excited to hear more!</Typography>
                            </Grid>
                        </Grid>
                        <Grid item container justifyContent={"center"} spacing={1}>
                            <Grid item>
                                <Button className={"animated-gradient"} size={"large"} variant={"contained"}
                                        component={Link} to={"/get-in-touch"}>Get in touch</Button>
                            </Grid>
                            <Grid item>
                                <Button size={"large"} variant={"outlined"} component={Link}
                                        to={"/services/custom-software"}>Find out more</Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Layout>
    )
}
export default Index;

export const Head: HeadFC = (props: HeadProps<any>) => (
    <PageMetadata location={props.location.pathname} metadata={props.data.page.metadata}/>
)

export const query = graphql`
{
    page: graphCmsPage(identifier: {eq: "home"}) {
        link {
            url
        }
        metadata {
            title
            description
            thumbnail {
              url
            }
            embedImage {
              url
            }
            jsonLD
        }
        banner {
            url
            alt
        }
    }
}`