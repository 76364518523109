import React from 'react';
import { Container, Grid } from "@mui/material";
import { graphql, useStaticQuery } from "gatsby";
import { SectionHeader } from "components/layout/section-header";
import { Product } from "models/schemas/product";
import { ProductCard } from "content/cards/product-card";

const ProductsBanner: React.FC = () => {

    const { products } = useStaticQuery(query);

    return (
        <Container maxWidth={'lg'}>
            <Grid container spacing={4}>
                <Grid item container>
                    <SectionHeader
                        title={"Products"}
                        description={"Created by us for your business."}
                    />
                </Grid>
                <Grid container item spacing={4} justifyContent={"center"}>
                    {products.nodes.map((product: Product) =>
                        <Grid key={product.name} item md={6} lg={4}>
                            <ProductCard 
                                product={product}
                            />
                        </Grid>
                    )}
                </Grid>
            </Grid>
        </Container>
    )
}
export { ProductsBanner }

const query = graphql`
{
    products: allGraphCmsProduct {
        nodes {
            page {
                link {
                    label
                    url
                    isInternal
                }
            }
            name
            summary
            link {
                label
                url
                isInternal
            }
            icon {
                url
                alt
            }
            wordmark {
                url
                alt
            }
        }
    }
}
`;