import React, { useCallback } from 'react';
import {
    Button,
    Card,
    CardContent,
    Container, FormControl,
    FormHelperText,
    Grid,
    Paper,
    TextField,
    Typography,
    useTheme
} from "@mui/material";
import * as layout from "themes/layout.module.scss";
import { Formik } from "formik";
import { EmailForm, emailFormInitialState } from "models/email-form";
import { emailFormValidation } from "models/validation/email-form-validation";
import { nameof } from 'helpers/typeHelper';
import { navigate } from "gatsby";

const LandingBanner: React.FC = () => {

    const theme = useTheme();

    const onSubmit = useCallback((value: EmailForm) => {
        navigate(`/get-in-touch?email=${value.email}`)
    }, [])

    return (
        <Container maxWidth={'lg'}>
            <Grid container minHeight={400} alignItems={"stretch"} justifyContent={"center"}>
                <Grid container item sm={7} spacing={2} alignContent={"center"}>
                    <Grid container item>
                        <Grid item xs={12}>
                            <Typography variant={"h3"} component={"h1"} color={"white"} textAlign={"center"}
                                        fontWeight={"bold"}>
                                <span style={{ color: theme.palette.primary.main }}>Bespoke</span> Software Solutions
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant={"h6"} component={"h2"} fontWeight={"normal"} color={"textSecondary"}
                                        textAlign={"center"}>
                                Driving innovation through passion and expertise
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container item alignItems={"stretch"} spacing={1}>
                        <Formik
                            initialValues={emailFormInitialState}
                            onSubmit={onSubmit}
                            validationSchema={emailFormValidation}
                        >
                            {({ values, touched, errors, handleChange, handleSubmit }) => (
                                <>
                                    <Grid item xs>
                                        <Card sx={{ p: 1, backgroundColor: "white" }}>
                                            <TextField
                                                name={nameof<EmailForm>("email")}
                                                onChange={handleChange}
                                                value={values.email}
                                                variant={"standard"}
                                                placeholder={"Enter your email"}
                                                sx={{ input: { color: layout.secondary, p: 0 } }}
                                            />
                                        </Card>
                                    </Grid>
                                    <Grid item>
                                        <Button
                                            variant={"contained"}
                                            color={"primary"}
                                            sx={{ height: "100%" }}
                                            onClick={() => handleSubmit()}
                                        >
                                            Say Hello!
                                        </Button>
                                    </Grid>
                                    <Grid item xs={12}>
                                        { touched.email && Boolean(errors.email) &&
                                            <FormHelperText error>
                                                {touched.email && errors.email}
                                            </FormHelperText>
                                        }
                                    </Grid>
                                    
                                </>
                            )}
                        </Formik>
                    </Grid>
                </Grid>
            </Grid>
        </Container>
    )
}
export { LandingBanner }