import React from "react";
import { Container, Grid, Typography } from "@mui/material";
import { graphql, useStaticQuery } from "gatsby";
import { Customer } from "models/schemas/customer";

export type CustomersBannerProps = {}

const CustomersBanner: React.FC<CustomersBannerProps> = () => {

    const { customers } = useStaticQuery(query);

    return (
        <Container maxWidth={'lg'}>
            <Grid container>
                <Grid container item spacing={4}>
                    <Grid item xs={12}>
                        <Typography variant={"h6"} textAlign={"center"} color={"textSecondary"}>Don't take our word for
                            it.</Typography>
                        <Typography variant={"h6"} textAlign={"center"} fontWeight={"bold"} color={"textPrimary"}>Here are
                            some businesses we've partnered with</Typography>
                    </Grid>
                    <Grid container item justifyContent={"center"} alignItems={"center"} spacing={4}>
                        {
                            customers.nodes.map((customer: Customer) => (
                                <Grid item xs={8} sm={3} md={2} key={customer.name}>
                                    <img src={customer.logo.url} alt={customer.logo.alt} width={"100%"}/>
                                </Grid>
                            ))}
                    </Grid>
                </Grid>
            </Grid>
        </Container>
    )
}
export { CustomersBanner }

const query = graphql`
{
    customers: allGraphCmsCustomer {
        nodes {
            name
            website
            logo {
                url
                alt
            }
        }
    }
}
`